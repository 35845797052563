<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ tableTitle }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="hiddenColTable"
                            :items="arrChaptersOfCourse"
                            class="elevation-1"
                            hide-default-footer
                            :loading="isLoadingChaptersOfCourse"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :single-expand="true"
                            :expanded.sync="expanded"
                            show-expand
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:expanded-item="{ headers, item }">
                                <td class="section__child-table" :colspan="headers.length">
                                    <DetailsOfSectionCollapse
                                        :courseChapter="item"
                                        :user_id="user_id"
                                    ></DetailsOfSectionCollapse>
                                </td>
                            </template>
                        </v-data-table>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24"></a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNoti from '../../components/CMS/Modal/Noti.vue';
import DetailsOfSectionCollapse from '../../views/CMS/DetailsOfSection.vue';
import { mixinToasts, mixinFormats } from '../../mixins';
import * as _ from 'lodash';

export default {
    mixins: [mixinToasts, mixinFormats],
    components: { ModalDelete, ModalNoti, DetailsOfSectionCollapse },

    data() {
        return {
            tableTitle: this.$t('cms.chapters_of_course'),
            tableColumns: [
                { text: this.$t('cms.section_name'), value: 'title', sortable: false, width: '100%' },
                { text: '', value: 'data-table-expand', width: '100px' },
            ],
            fieldsSearch: [
                {
                    title: 'Section name',
                    value: 'title',
                },
            ],
            selectedField: 'title',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
            arrChaptersOfCourse: [],

            expanded: [],
            user_id: '',
        };
    },
    created() {
        this.hiddenFieldModal(this.dialogEdit);
    },
    computed: {
        ...mapState('chaptersOfCourse', [
            'isLoadingChaptersOfCourse',
            'chaptersOfCourses',
            'chaptersOfCoursePagination',
        ]),
        formTitle() {
            return this.cuIndex === -1 ? 'New Item' : 'Edit Item';
        },
        hiddenColTable() {
            return this.tableColumns.filter((s) => s.value !== 'password');
        },
    },
    async mounted() {
        await this.getChaptersOfCourseList({ page: this.page, field: this.selectedField, search: this.search });
        if (this.chaptersOfCourses) {
            const arrCourses = this.chaptersOfCourses?.course_chapters;
            const arrChapters = arrCourses.map((item) => ({
                id: item?.chapter?.id,
                title: item?.chapter?.title,
                course_sections: item?.course_sections,
            }));
            this.arrChaptersOfCourse = arrChapters;
        }
    },
    watch: {
        page(val) {
            this.getChaptersOfCourseList({ page: val, field: this.selectedField, search: this.search });
        },
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
    },
    methods: {
        ...mapActions('chaptersOfCourse', [
            'getChaptersOfCourses',
            'postNewChaptersOfCourse',
            'putOneChaptersOfCourse',
            'deleteOneChaptersOfCourse',
        ]),
        async getChaptersOfCourseList(data) {
            const getIdCourse = this.$router?.history?.current?.query?.course_id;
            const getIdUser = this.$router?.history?.current?.query?.user_id;
            this.user_id = getIdUser;
            this.page = data?.page || 1;
            this.selectedField = data?.field;
            this.search = data?.search || '';
            await this.getChaptersOfCourses({ course_id: getIdCourse, user_id: getIdUser }, data);
        },
        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },
        onAddNewItem() {
            this.dialogEdit = false;
            this.dialogCU = true;
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.chaptersOfCourses.indexOf(item.id);
            this.cuItem = Object.assign({}, item);
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.chaptersOfCourses.indexOf(item.id);
            this.cuItem = Object.assign({}, item);
        },
        onDeleteItem(item) {
            this.cuIndex = this.chaptersOfCourses.indexOf(item);
            this.cuItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            // this.chaptersOfCourses.splice(this.cuIndex, 1);
            const res = await this.deleteOneChaptersOfCourse(this.cuItem.id);
            if (res?.isSuccess) {
                await this.getChaptersOfCourseList({ page: this.page, field: this.selectedField, search: this.search });
                this.showToast('success', res?.data?.message);
            } else {
                this.showError(res?.mess);
            }
            this.closeDelete();
        },
        closeCU() {
            this.dialogCU = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeNoti() {
            this.dialogNoti = false;
        },
        async onSaveItem() {
            this.dialogNoti = true;
            if (this.cuIndex === -1) {
                const res = await this.postNewChaptersOfCourse(this.cuItem);
                if (res?.isSuccess) {
                    this.formMessage = res?.mess || 'Created success!';
                    await this.getChaptersOfCourseList({ page: 1, field: 'title', search: '' });
                } else {
                    this.formMessage = res?.mess || 'Created fail!';
                }
            } else {
                const res = await this.putOneChaptersOfCourse(this.cuItem);
                if (res?.isSuccess) {
                    this.formMessage = res?.mess || 'Updated success!';
                    await this.getChaptersOfCourseList({
                        page: this.page,
                        field: this.selectedField,
                        search: this.search,
                    });
                } else {
                    this.formMessage = res?.mess || 'Updated fail!';
                }
            }
        },
        onSearchItem: _.debounce(function (e) {
            this.getChaptersOfCourseList({ page: 1, field: this.selectedField, search: this.search });
        }, 500),
        handleChangeSelect(value) {
            this.getChaptersOfCourseList({ page: 1, field: value, search: this.search });
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.section__child-table {
    padding: 20px 20px 0 !important;
}
</style>
