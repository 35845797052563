<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ tableTitle }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="hiddenColTable"
                            :items="arrSections"
                            class="elevation-1"
                            hide-default-footer
                            :loading="isLoadingUser"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:[`item.type`]="{ item }">
                                <div class="my-2">
                                    <span>{{ $t(`cms.${item.type}`) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.pass_score`]="{ item }">
                                <v-progress-linear
                                    :value="item.progress"
                                    height="25"
                                    :style="{ width: '75%' }"
                                    v-if="item.progress"
                                >
                                    <strong> {{ item.progress }}% </strong>
                                </v-progress-linear>
                                <v-progress-linear
                                    :value="item.user_score"
                                    height="25"
                                    :style="{ width: '75%' }"
                                    v-if="item.user_score"
                                >
                                    <strong> {{ item.user_score }}% </strong>
                                </v-progress-linear>
                            </template>
                            <template v-slot:[`item.last_time_join`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(item.last_time_join) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex" v-if="item.type === 'test'">
                                    <router-link
                                        :to="{
                                            path: `/records-of-test?course_id=${course_id}&user_id=${user_id}&test_id=${item.id}`,
                                        }"
                                    >
                                        <v-btn color="indigo" class="mr-2" dark small>
                                            <v-icon left> mdi mdi-format-list-bulleted-type </v-icon>
                                            {{ $t('cms.detail') }}
                                        </v-btn>
                                    </router-link>
                                </div>
                                <div class="d-flex" v-if="item.type === 'form'">
                                    <router-link
                                        :to="{
                                            path: `/records-of-form?course_id=${course_id}&user_id=${user_id}&form_id=${item.id}`,
                                        }"
                                    >
                                        <v-btn color="indigo" class="mr-2" dark small>
                                            <v-icon left> mdi mdi-format-list-bulleted-type </v-icon>
                                            {{ $t('cms.detail') }}
                                        </v-btn>
                                    </router-link>
                                </div>
                            </template>
                        </v-data-table>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24"></a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UserModal from '../../components/CMS/Modal/User.vue';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNoti from '../../components/CMS/Modal/Noti.vue';
import { mixinFormats } from '../../mixins';

export default {
    mixins: [mixinFormats],
    components: { UserModal, ModalDelete, ModalNoti },
    props: {
        courseChapter: { type: Object },
        user_id: { type: String },
    },
    data() {
        return {
            tableTitle: this.$t('cms.details_of_section'),
            tableColumns: [
                { text: this.$t('form.title'), value: 'title', sortable: false, width: '200px' },
                { text: this.$t('form.type'), value: 'type', sortable: false, width: '200px' },
                { text: this.$t('cms.progress_best_score'), value: 'pass_score', sortable: false, width: '200px' },
                { text: this.$t('cms.last_study_date'), value: 'last_time_join', sortable: false, width: '200px' },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '100px' },
            ],
            fieldsSearch: [
                {
                    title: '',
                    value: '',
                },
            ],
            selectedField: '',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
            arrSections: [],
            course_id: '',
            user_id: '',
        };
    },
    created() {
        this.hiddenFieldModal(this.dialogEdit);
    },
    computed: {
        ...mapState('user', ['isLoadingUser', 'users', 'usersPagination']),
        formTitle() {
            return this.cuIndex === -1 ? 'New Item' : 'Edit Item';
        },
        hiddenColTable() {
            return this.tableColumns.filter((s) => s.value !== 'password');
        },
    },
    async mounted() {
        await this.handleDataSection();
    },
    watch: {
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
        courseChapter() {
            this.arrSections = [];
            this.handleDataSection();
        },
    },
    methods: {
        renderScrore(item) {
            if (item?.records?.length === 0) {
                return 0;
            } else {
                return (
                    Math.ceil(
                        item.type === 'lesson'
                            ? item?.records?.[0]?.progress
                            : item?.records?.reduce((accu, elem) => {
                                  return accu?.user_score > elem?.user_score ? accu?.user_score : elem?.user_score;
                              }),
                    ) || 0
                );
            }
        },

        async handleDataSection() {
            this.course_id = this.$router?.history?.current?.query?.course_id;
            this.user_id = this.$router?.history?.current?.query?.user_id;

            this.courseChapter.course_sections.forEach((item) => {
                if (item.section.lesson) {
                    this.arrSections.push({ ...item.section.lesson, type: 'lesson' });
                }
                if (item.section.test) {
                    this.arrSections.push({ ...item.section.test, type: 'test' });
                }
                if (item.section.form) {
                    this.arrSections.push({ ...item.section.form, type: 'form' });
                }
            });
        },

        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}
</style>
