var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ padding: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h5',{staticClass:"font-semibold m-0"},[_vm._v(_vm._s(_vm.tableTitle))])])],1)]},proxy:true}])},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.hiddenColTable,"items":_vm.arrSections,"hide-default-footer":"","loading":_vm.isLoadingUser,"loading-text":_vm.$t('cms.loading'),"page":_vm.page,"no-data-text":_vm.$t('cms.no_data_available')},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.$t(("cms." + (item.type)))))])])]}},{key:"item.pass_score",fn:function(ref){
var item = ref.item;
return [(item.progress)?_c('v-progress-linear',{style:({ width: '75%' }),attrs:{"value":item.progress,"height":"25"}},[_c('strong',[_vm._v(" "+_vm._s(item.progress)+"% ")])]):_vm._e(),(item.user_score)?_c('v-progress-linear',{style:({ width: '75%' }),attrs:{"value":item.user_score,"height":"25"}},[_c('strong',[_vm._v(" "+_vm._s(item.user_score)+"% ")])]):_vm._e()]}},{key:"item.last_time_join",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.getFormatDate(item.last_time_join)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.type === 'test')?_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
                                        path: ("/records-of-test?course_id=" + _vm.course_id + "&user_id=" + _vm.user_id + "&test_id=" + (item.id)),
                                    }}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"indigo","dark":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi mdi-format-list-bulleted-type ")]),_vm._v(" "+_vm._s(_vm.$t('cms.detail'))+" ")],1)],1)],1):_vm._e(),(item.type === 'form')?_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
                                        path: ("/records-of-form?course_id=" + _vm.course_id + "&user_id=" + _vm.user_id + "&form_id=" + (item.id)),
                                    }}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"indigo","dark":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi mdi-format-list-bulleted-type ")]),_vm._v(" "+_vm._s(_vm.$t('cms.detail'))+" ")],1)],1)],1):_vm._e()]}}],null,true)})]],2)],1)],1),_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }